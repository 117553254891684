.nav {
  margin-bottom: 0.5rem;
}

.logo {
  float: left;
  font-size: 40px;
  height: 46px;
  padding-top: 3px;
  padding-left: 3px;
  border-bottom: 1px solid #f0f0f0;
}
