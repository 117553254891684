.text h1 {
  font-weight: 500;
}

.text h2 > small {
  font-weight: 300;
  display: block;
  font-size: 0.9rem;
  line-height: 1;
  opacity: 0.75;
}

.text p,
.text ol,
.text ul,
.text li {
  font-weight: 300;
}
