.cover {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 1px;
  width: calc(100% - 2px);
  height: 150px;
}

.v {
  font-weight: 400;
  font-size: 0.8em;
  opacity: 0.4;
}
