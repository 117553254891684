.container {
  margin-top: 16px;
  padding: 8px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.container + .container {
  margin-top: 0;
  border-top: 0;
}

.container:last-of-type {
  margin-bottom: 16px;
}

.hItem {
  opacity: 0;
}

.hContainer:hover .hItem {
  opacity: 1;
}
