.weekend {
  background-color: rgba(24, 144, 255, 0.025);
}

.weekend:not(:global(.ant-select-item-option-disabled)) {
  background-color: rgba(24, 144, 255, 0.1);
}

.weekend:not(:global(.ant-select-item-option-disabled)):hover {
  background-color: rgba(24, 144, 255, 0.15);
}
