.title,
.title2 {
  text-align: right;
}

.title2 {
  line-height: 2;
}

@media (max-width: 575px) {
  .title,
  .title2 {
    text-align: left;
    margin-bottom: -16px;
  }
}

.bestelling {
  margin: 0.5rem 2rem;
  padding: 0.5rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}

.hItem {
  display: none;
}

.hContainer:hover .hItem {
  display: unset;
}

.sel {
  display: inline-block;
}

.sel :global(.ant-select) {
  width: 100px;
}
