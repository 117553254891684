.pic {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100px;
}

.pic button {
  width: 56px !important;
  height: 56px !important;
  font-size: 26px !important;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
}

.pic:hover button,
.pic button:focus {
  opacity: 1;
}

.pic button span {
  font-size: 26px !important;
}

h2.hContainer .hItem {
  vertical-align: top;
  margin-top: 4px;
}

.hItem {
  display: none;
}

.hContainer:hover .hItem {
  display: unset;
}

.description {
  position: relative;
}

.description .hItem {
  position: absolute;
  top: 0px;
  right: 0px;
}
