.map {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 95%;
  position: relative;
  text-align: right;
  border-radius: 0.5rem;
}

.map iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  border-radius: 0.5rem;
}
