.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.spin > * {
  transform: scale(3);
}
