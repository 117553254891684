.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.header h1 {
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

.weekend {
  background: rgba(24, 144, 255, 0.1);
  position: relative;
}

.weekend::before {
  content: '';
  height: 12px;
  background: rgba(24, 144, 255, 0.1);
  position: absolute;
  top: -12px;
  width: 100%;
}

.weekend::after {
  content: '';
  height: 12px;
  background: rgba(24, 144, 255, 0.1);
  position: absolute;
  top: 100%;
  width: 100%;
}
