.title {
  text-align: right;
}

@media (max-width: 575px) {
  .title {
    text-align: left;
    margin-bottom: -16px;
  }
}
